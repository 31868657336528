.Header {
    height: 100vh;
    color: white;
    position: relative;
}

.Header__container {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 0;
}

.Header__title {
    display: inline;
    margin-bottom: 1rem;
    font-size: 4rem;
    font-weight: 700;
    text-shadow: 5px 2px #222324, 2px 4px #222324, 3px 5px #222324;
}

.Header__content {
    margin: 1.5rem 0;
    font-size: 1.75rem;
}

/* Media Queries */

@media only screen and (max-width: 1400px) {

    .Header__container {
        left: 40%;
        top: 60%
    }
}

@media only screen and (max-width: 1000px) {

    .Header__container {
        width: 70%;
    }

    .Header__title {
        font-size: 3.5rem;
    }

    .Header__content {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 700px) {

    .Header__container {
        width: 80%;
        left: 50%;
    }
}

@media only screen and (max-width: 600px) {

    .Header__container {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {

    .Header__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .Header__title {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 400px) {

    .Header__title {
        font-size: 2.5rem;
    }

    .Header__content {
        font-size: 1.25rem;
    }
}