.Skills {
    padding: 3rem 2rem;
}

.Skills__title {
    text-align: center;
}

.Skills__table {
    border-spacing: 0 5px;
    padding: 1rem;
    margin: 2rem auto;
}

.Skills__table__header {
    margin: auto 1rem;
}

.Skills__table__data {
    padding: 0.5rem 1rem;
    text-align: center;
    border-bottom: 1px solid #E1F0FA;
    background-color: rgba(225, 240, 250, 0.2);
}

.Skills__table__data:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Skills__table__data:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Skills__table__name {
    text-align: end;
}

.Skills__table__span {
    padding: 0.5rem;
    border-radius: 4px;
    background-color: white;
}

.Skills__table__experience {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Skills__table__level {
    width: 50px;
    height: 50px;
    color: white;
    background-color: black;
    opacity: 0.8;
}

.Skills__icons {
    display: none;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    margin: 3rem auto;
}

.Skills__icons__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 1rem;
}

.Skills__icon {
    width: 130px;
    height: auto;
    overflow: hidden;
}

.Skills__icon__short {
    width: auto;
    height: 130px;
    overflow: hidden;
}

/* Media Queries */

@media only screen and (max-width: 900px) {

    .Skills__table {
        display: none;
    }

    .Skills__icons {
        display: flex;
    }
}

@media only screen and (max-width: 400px) {

    .Skills {
        padding: 2rem 1rem;
    }
}