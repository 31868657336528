.Contact {
    padding: 2rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Contact__title {
    color: white;
    text-align: center;
}

.Contact__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
}

/* Media Queries */

@media only screen and (max-width: 600px) {

    .Contact__form {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {

    .Contact__form {
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {

    .Contact {
        padding: 2rem 1rem;
    }
}

@media only screen and (max-width: 300px) {

    .Contact__title {
        font-size: 2.5rem;
    }
}