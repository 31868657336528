/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200&family=Gemunu+Libre:wght@200&family=Rajdhani:wght@300;400&family=Rationale&family=Smooch+Sans:wght@100&display=swap');

/* Universal Styles */

* , *::before, *::after {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  font-size: 2rem;
  font-family: Rajdhani, sans-serif;
  font-weight: 300;
}

a {
  color: inherit;
  text-decoration: inherit;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: lightseagreen;
}